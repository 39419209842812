import React, { useState, useEffect } from 'react'
import { Link } from 'react-head'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Box from '@material-ui/core/Box'
import { encode } from 'js-base64'
import { Button } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'

import Annotator from '../../../components/TextAnnotation/Annotator'
import { compareByCreatedAt, compareByGenerationId, annotationTypes } from '../../../utils/helpers'

/// Gets all dependent data from Retool, injected via iframe URL.
const getTokenInput = (sentenceTranscript, wordTranscript) => (sentenceTranscript?.segments ?? []).map((sentenceSegment) => {
  const sentenceIn = sentenceSegment.in_point[0]
  const sentenceOut = sentenceSegment.out_point[0]

  return {
    text: sentenceSegment.metadata?.text,
    assetId: sentenceTranscript.asset_id,
    tokens: wordTranscript.segments.filter((wordSegment) => {
      // get all words as part of this sentence segment.
      return wordSegment.in_point != null && wordSegment.in_point[0] >= sentenceIn && wordSegment.out_point[0] <= sentenceOut
    }).map((word) => ({
      ...word
    }))
  }
})

const AnnotationPage = () => {
  const [annotationType, setAnnotationType] = useState('highlight')
  const [transcripts, setTranscripts] = useState([])
  const [annotations, setAnnotations] = useState({})
  const [generationId, setGenerationId] = useState(undefined)
  const [activeCorrectionToken, setActiveCorrectionToken] = useState(null)

  useEffect(() => {
    initialTranscriptsFromUrl()
  }, [])

  useEffect(() => {
    if (transcripts.length) {
      const createdAt = moment().utc().format()

      const segments = Object.values(annotations).flat()

      segments.filter(x => x?.data?.annotation === 'correction').forEach(correctionSegment => {
        // every highlight annotation for the same token needs to include the 'corrected' text. Match on in/out points
        const annotationKey = JSON.stringify([correctionSegment.in_point, correctionSegment.out_point])
        const associatedHighlight = segments.filter(x => x?.data?.annotation === 'highlight').find(highlightSegment => JSON.stringify([highlightSegment.in_point, highlightSegment.out_point]) === annotationKey)

        if (!_.isEmpty(associatedHighlight)) {
          associatedHighlight.data.text = correctionSegment.data.text
          associatedHighlight.data.original_text = correctionSegment.data.original_text
        }
      })

      const layer = {
        layer_type: 'annotations',
        source: 'admin',
        asset_id: transcripts[0].assetId,
        segments,
        generation_id: generationId,
        metadata: {
          created_at: createdAt,
          updated_at: createdAt
        },
        created_at: createdAt,
        updated_at: createdAt
      }

      const encoded = encode(JSON.stringify(layer))
      window.parent?.postMessage({ type: 'annotations', data: layer }, '*')
      window.parent?.postMessage({ type: 'encodedAnnotations', data: encoded }, '*')
    }
  }, [annotations])

  const handleAnnotationTypeChange = (event) => {
    setActiveCorrectionToken(null)
    setAnnotationType(event.target.value)
  }

  const handleTokenClick = (annotationKey) => {
    if (annotationType === 'correction') {
      setActiveCorrectionToken(annotationKey)
    } else {
      handleAnnotation(annotationKey)
    }
  }

  const handleAnnotation = (annotationKey, textOverride) => {
    let token = null

    transcripts.forEach((sentence) => {
      const tmp = sentence.tokens.find((t) => _.isEqual(JSON.stringify([t.in_point, t.out_point]), annotationKey))

      if (tmp) {
        token = tmp
      }
    })

    // clone existing annotations before updating for this token
    const newAnnotations = _.cloneDeep(annotations)

    const annotationsForToken = annotations[annotationKey]
    const annotationIndexForType = annotationsForToken?.findIndex(x => x?.data?.annotation === annotationType)

    if (annotationIndexForType >= 0) {
      newAnnotations[annotationKey].splice(annotationIndexForType, 1)

      if (annotationType !== 'correction') {
        setAnnotations(newAnnotations)
        setActiveCorrectionToken(null)
        return
      }
    }

    // fresh copy of token so we can mutate safely
    const newToken = _.cloneDeep(token)
    const { text } = token.metadata

    newToken.metadata = {}
    newToken.data = { annotation: annotationType, text }
    newToken.segment_type = 'annotation'

    if (annotationType === 'correction' && textOverride) {
      newToken.data.text = textOverride
      newToken.data.original_text = text
    }

    // the following are internal fields only. we should remove them when forming the layer segments
    delete newToken.id

    if (annotationType === 'remove') {
      newAnnotations[annotationKey] = [newToken]
    } else {
      const existingAnnotationsForToken = !_.isEmpty(newAnnotations[annotationKey]) ? _.cloneDeep(newAnnotations[annotationKey].filter(x => x.data?.annotation !== 'remove')) : []
      newAnnotations[annotationKey] = [...existingAnnotationsForToken, newToken]
    }

    setAnnotations(newAnnotations)
    setActiveCorrectionToken(null)
  }

  const initialTranscriptsFromUrl = async () => {
    if (window.location.hash) {
      const urls = JSON.parse(decodeURI(window.location.hash.substring(1)))
      const fetchPromises = await Promise.allSettled(urls.map(url => fetch(url).then(res => res.json())))
      const layers = fetchPromises.filter(promise => promise.status === 'fulfilled').map(promise => promise.value).sort(compareByCreatedAt)

      const sentenceLayers = layers.filter(x => x.layer_type === 'transcription/sentences').sort(compareByGenerationId)
      const wordLayers = layers.filter(x => x.layer_type === 'transcription/words').sort(compareByGenerationId)

      let sentenceLayer = sentenceLayers[0]
      let wordLayer = wordLayers[0]

      // hack to solve two things
      // 1) no layers with a generation_id
      // 2) the case where we have generation_ids, but we also have sentence layers with a transcription/words type

      // if one has a generation_id and one does not. we are dealing with case #2
      const brokenSentenceType = sentenceLayer && !_.isEmpty(sentenceLayer?.generation_id) && _.isEmpty(wordLayer?.generation_id)

      if (!sentenceLayer || brokenSentenceType) {
        // only consider layers i
        const all = layers.filter(x => _.isEmpty(x.generation_id) && x.layer_type.indexOf('transcription/') === 0).sort((a, b) => a.segments.length - b.segments.length)
        sentenceLayer = all[0]
        wordLayer = all[1]
      }

      const transcriptInput = getTokenInput(sentenceLayer, wordLayer)
      setTranscripts(transcriptInput)
      setGenerationId(sentenceLayer?.generation_id)

      // set state from most recent annotation file
      const annotationLayers = layers.filter(x => x.layer_type === 'annotations').sort(compareByCreatedAt)

      if (annotationLayers.length) {
        // transform from an array of segments, to { tokenId: segment }
        // in order to do this, we need to match token IDs to the provided segment
        const layer = annotationLayers[annotationLayers.length - 1]

        const annotationMap = {}

        layer.segments.forEach((x) => {
          const annotationKey = JSON.stringify([x.in_point, x.out_point])

          if (transcriptInput.find(t => t.tokens.find(token => _.isEqual(annotationKey, JSON.stringify([token.in_point, token.out_point]))))) {
            if (_.isEmpty(annotationMap[annotationKey])) {
              annotationMap[annotationKey] = [x]
            } else {
              annotationMap[annotationKey].push(x)
            }
          }
        })

        setAnnotations(annotationMap)
      }
    }
  }

  return (
    <Box>
        <Link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <Link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <RadioGroup row value={annotationType} onChange={handleAnnotationTypeChange}>
            {annotationTypes.map(at => <FormControlLabel key={at.value} value={at.value} control={<Radio style={{ color: at.color } } />} label={at.label} />)}
            <Button disableElevation onClick={() => {
              initialTranscriptsFromUrl()
              setAnnotations({})
              setActiveCorrectionToken(null)
            }}>Clear All</Button>
        </RadioGroup>

        <Annotator sentences={transcripts} annotationTypes={annotationTypes} annotations={annotations} activeCorrectionToken={activeCorrectionToken} onTokenClick={handleTokenClick} setAnnotation={handleAnnotation} />
    </Box>
  )
}

export default AnnotationPage
