import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Tooltip, Typography } from '@material-ui/core'
import _ from 'lodash'
import Correction from './Correction'

const getTokenStyle = (colors) => {
  if (!colors?.length) {
    return null
  }

  if (colors.length === 1) {
    return { background: colors[0] }
  }

  const gradient = colors.map((c, i) => {
    const start = (i / colors.length) * 100
    const end = ((i + 1) / colors.length) * 100

    return `, ${c} ${start}% ${end}%`
  }).join('')
  return { background: `linear-gradient(110deg${gradient})` }
}

const InnerToken = styled.span(props => ({
  margin: '0 1px',
  padding: '2px 3px',
  cursor: 'pointer',
  color: _.isEmpty(props.colors) ? '#000' : '#FFF',
  ...getTokenStyle(props.colors)
}))

const getDisplayText = (annotations, token) => {
  const correction = annotations?.find(c => c.data?.annotation === 'correction')

  if (correction) {
    return correction.data.text
  }

  return token.metadata.text
}

const Token = ({ token, colors, onClick, setAnnotation, tooltipOpen, annotations }) => {
  const TooltipInner = (<Correction text={token?.metadata?.text} onSave={setAnnotation} />)

  const displayText = getDisplayText(annotations, token)

  return (
    <Tooltip
        PopperProps={{
          disablePortal: false,
          style: {
            pointerEvents: 'auto'
          }
        }}
        open={tooltipOpen}
        title={TooltipInner}
        arrow={true}
        >
            <InnerToken colors={colors} onClick={() => onClick(token)}>
                <Typography variant='body2' component='span'>{displayText}</Typography>
            </InnerToken>
    </Tooltip>
  )
}

Token.propTypes = {
  token: PropTypes.shape({
    metadata: PropTypes.shape({
      text: PropTypes.string
    })
  }),
  onClick: PropTypes.func.isRequired,
  setAnnotation: PropTypes.func.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  tooltipOpen: PropTypes.bool
}

export default Token
