import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Button, TextField } from '@material-ui/core'

const SaveButton = styled(Button)({
  marginBottom: '5px !important',
  width: '100%'
})

const CssTextField = styled(TextField)({
  margin: '5px 0 15px 0 !important',

  '& label': {
    color: 'rgba(255,255,255,0.5)'
  },
  '& .MuiInput-input': {
    color: 'white'
  },
  '& label.Mui-focused': {
    color: 'white'
  },
  '& .MuiInput-underline:before, & .MuiInput-underline:after': {
    borderBottomColor: 'white !important'
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'white'
    },
    '&:hover fieldset': {
      borderColor: 'white !important'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white'
    }
  }
})

const Correction = (props, ref) => {
  const { text, onSave } = props
  const [correctedText, setCorrectedText] = useState(text)

  return (

    <div {...props}>
        <div>
            <CssTextField variant="standard" label="Corrected Text" onChange={e => {
              setCorrectedText(e.target.value)
            } } />
        </div>
        <SaveButton variant="contained" color="primary" disableElevation onClick={() => {
          onSave(correctedText)
        }}>Save</SaveButton>
    </div>
  )
}

Correction.propTypes = {
  text: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired
}

export default Correction
