import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'
import Token from './Token'

const Sentence = styled.div({
  margin: '15px 0',
  overflowWrap: 'break-word'
})

const getTokenColors = (annotationTypes, annotationKey, annotations) => {
  if (Object.keys(annotations).indexOf(annotationKey) < 0) {
    return null
  }

  const annotationsForTokenKey = annotations[annotationKey]
  const colors = annotationsForTokenKey?.map(x => annotationTypes.find(at => at.value === x?.data?.annotation)?.color) ?? []

  // tokens can only have one annotation for the moment
  return colors
}

const Annotator = ({ annotationTypes, sentences, setAnnotation, onTokenClick, annotations, activeCorrectionToken }) => {
  return (
    <div>
        {sentences.map((s, i) => (
            <Sentence key={i}>
                {s.tokens.map(token => {
                  const annotationKey = JSON.stringify([token.in_point, token.out_point])
                  const annotationsForToken = annotations[annotationKey]

                  return (
                      <Token key={annotationKey} token={token} annotations={annotationsForToken} tooltipOpen={annotationKey === activeCorrectionToken} colors={getTokenColors(annotationTypes, annotationKey, annotations)} onClick={() => onTokenClick(annotationKey)} setAnnotation={(textCorrection) => setAnnotation(annotationKey, textCorrection)}>
                          <Typography variant='body2' component='span'>{token.metadata.text}</Typography>
                      </Token>
                  )
                })}
            </Sentence>
        ))}
    </div>
  )
}

Annotator.propTypes = {
  annotationTypes: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    color: PropTypes.string
  })),
  sentences: PropTypes.arrayOf(PropTypes.shape(
    {
      text: PropTypes.string,
      tokens: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired
      }))
    }
  )),
  annotations: PropTypes.object,
  setAnnotation: PropTypes.func.isRequired,
  onTokenClick: PropTypes.func.isRequired,
  activeCorrectionToken: PropTypes.string
}

export default Annotator
