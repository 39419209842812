
export const annotationTypes = [
  { label: 'Highlight', value: 'highlight', color: '#3F51B5' },
  { label: 'Remove', value: 'remove', color: '#F44336' },
  { label: 'Correction', value: 'correction', color: '#357a38', style: 'border' }
]

export const compareByCreatedAt = (a, b) => {
  const minDate = new Date(1920, 1, 1)

  return new Date(a.created_at ?? minDate) - new Date(b.created_at ?? minDate)
}

export const compareByGenerationId = (a, b) => {
  if (!a.generation_id) {
    return -1
  }

  if (a.generation_id < b.generation_id) {
    return -1
  }
  if (a.generation_id > b.generation_id) {
    return 1
  }
  return 0
}
